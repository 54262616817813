:root {
	--toastify-color-light: #fff;
	--toastify-color-dark: #4f6375;
	--toastify-color-info: #3498db;
	--toastify-color-success: #69e9b3;
	--toastify-color-warning: #f1c40f;
	--toastify-color-error: #f5333f;
	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);
}

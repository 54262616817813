.navlink:hover {
	filter: brightness(0.8);
}

.navlink-item:hover {
	background-color: rgba(131, 131, 131, 0.129);
}

.dropdown {
	position: absolute;
	min-width: 130px;
	transform-origin: top;
	top: 64px;
}

.dropdown-primary-enter {
	position: absolute;
	transform: scaleY(0%);
}
.dropdown-primary-enter-active {
	position: absolute;
	transform: scaleY(100%);
	transition: ease;
	transition-duration: 300ms;
}
.dropdown-primary-exit {
	position: absolute;
}
.dropdown-primary-exit-active {
	position: absolute;
	transform: scaleY(0%);
	transition: ease;
	transition-duration: 300ms;
}

.paragraph-3 {
	/* autoprefixer: off */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}

.paragraph-2 {
	/* autoprefixer: off */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
}
